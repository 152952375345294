import React from "react"

export default function ErrorC() {
    return(
        <>
        <div className="errorContainer">
            <h1>Oops, something went wrong.</h1>
            <span>Our appologies, an error occured while trying to process your request. Please refresh the page and try again.</span>
            <p>If you're having issues we would love to hear about it: We welcome you to email us at this address Effect@keemail.me with any details, questions or screenshots that could help us. Thank you.</p>
        </div>
        </>
    )
}
