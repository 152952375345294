import React from "react"
import Title from "./subcomponents/Title"
import HomeForm from "./subcomponents/HomeForm"

export default function DigitalGardenHome() {
    return(
        <>
        <div className="homeLogo">
            <img className="logoImage" src="images/logo.png" alt="logo" />
        </div>
        <div className="homeContainer">
            <div className="homeText">
                <div className="textContainer">
                    <Title />
                </div>
                <div className="formContainer">
                    <HomeForm />
                </div>
            </div>
            <div className="homeImageContainer">
                <img src="images/homeImage.png" alt="homeImage" className="homeImage"></img>
            </div>
        </div>
        </>
    )
}