import React from "react"
import { Route, Routes } from 'react-router-dom'
import DigitalGardenHome from "./DigitalGardenHome"
import DigitalGardenDisplay from "./DigitalGardenDisplay"
import DigitalGardenUpdate from "./DigitalGardenUpdate"

export default function App() {
    return(
        <>
        <Routes>
            <Route path="/" element={<DigitalGardenHome />}/>
            <Route path="/results/:id" element={<DigitalGardenDisplay />} />
            <Route path="/ephemera" element={<DigitalGardenUpdate />} />
        </Routes>
        </>
    )
}