import React from "react"
import Loader from "./Loader"

export default function DisplayGrid({ userData }) {
    return(
        userData.name
        ? <>
            <div className="responsePanel">
                <div className="titlePage">
                    <p>{userData.name}</p>
                    <p className="inspiredBy">INSPIRED BY :</p>
                    <p className="userMessage">{userData.message}</p>
                </div>
            </div>
            <div className="responsePanel">
                <p>{userData.intro.split(':')[1]}</p>
                <img className="aiImage" src={userData.sdImg1} alt="aiImage" />
            </div>
            <div className="responsePanel">
                <p>{userData.conflict.split(':')[1]}</p>
                <img className="aiImage" src={userData.sdImg2} alt="aiImage" />
            </div>
            <div className="responsePanel">
                <p>{userData.attempt.split(':')[1]}</p>
                <img className="aiImage" src={userData.sdImg3} alt="aiImage" />
            </div>
            <div className="responsePanel">
                <p>{userData.climax.split(':')[1]}</p>
                <img className="aiImage" src={userData.sdImg4} alt="aiImage" />
            </div>
            <div className="responsePanel">
                <p>{userData.resolution.split(':')[1]}</p>
                <img className="aiImage" src={userData.sdImg5} alt="aiImage" />
            </div>
            <div className="responsePanel">
                <p>{userData.reflection.split(':')[1]}</p>
                <img className="aiImage" src={userData.sdImg6} alt="aiImage" />
            </div>
            <div className="responsePanel">
                <div className="creditsPage">
                    <div className="creditText">
                        <p>Created as part of the Digital Garden installation</p>
                    </div>
                    <div className="creditText">
                        <p>Creative Team:</p>
                    </div>
                    <div className="creditText">
                        <p>Darryl Chin</p>
                        <p>Kristin Li</p>
                        <p>Kyung Ho Min</p>
                    </div>
                    <div className="creditText">
                        <p>Development:</p>
                    </div>
                    <div className="creditText">
                        <p>Ford Jones</p>
                        <p>Qianye Lin</p>
                    </div>
                    <div className="creditText">
                        <p>Have another go by visiting</p>
                        <p>https://www.piece.nz/</p>
                    </div>
                </div>
            </div>
        </>
        : <Loader />
    )
}
