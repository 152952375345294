import React from "react";
import { Link, Outlet } from "react-router-dom";

export default function UrlC({ id }) {
    return(
        <>
        <div className="homeUrl">
            <Link to={`https://www.piece.nz/results/${id}`}>{`https://www.piece.nz/results/${id}`}</Link>
            <Outlet />
        </div>
        </>
    )
}
