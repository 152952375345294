import React, { useState } from "react"
import Loader from "./Loader"
import ErrorC from "./Error"
import UrlC from "./url";
import { v4 as uuidv4 } from 'uuid';

const lambda = process.env.REACT_APP_DG_ENTRY_POINT
const uniqueId = uuidv4()

export default function HomeForm() {
    const [form, setForm] = useState({id: `${uniqueId}`, name: "", email: "pieceinbox@gmail.com", message: ""})
    const [errorC, setErrorC] = useState(false)
    const [loading, setLoading] = useState(false)
    const [urlC, setUrlC] = useState(false)

    function handleChange(e) {
        e.preventDefault()
        return setForm({...form, [e.target.name]: e.target.value})
    }

    function handleClick(e) {
        e.preventDefault()
        if(form.name !== "" && form.email !== "" && form.message !== "" ) {
            setLoading(true)
            try {
                const response = fetch(lambda, {
                    method: "POST",
                    statusCode: 202,
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(form)
                })
                setTimeout(() => {
                    setLoading(false)
                    setUrlC(true)
                }, 2000)
                return response

            } catch (error) {
                setTimeout(() => {
                    setLoading(false)
                    setErrorC(true)
                }, 5000)
                console.error(error.message)
                console.trace()    
            }
        } else {
            alert("Please fill out the required fields and try again.")
        }
    }

    return(
        loading ?
        <Loader />
        : errorC ?
        <ErrorC />
        : urlC ?
        <UrlC id={form.id}/>
        : <>
            <div className="form">
                <form>
                    <div className="formName">
                        <label className="formLabel" htmlFor="name">
                            Name: 
                        </label>
                        <input 
                            type="text" 
                            name="name"
                            value={form.name}
                            onChange={handleChange} 
                            required
                            className="textInput">
                        </input>
                    </div>
                    <div className="formEmail">
                        <label className="formLabel" htmlFor="email" hidden>
                            Email: 
                        </label>
                        <input 
                            type="email" 
                            name="email"
                            value={form.email}
                            onChange={handleChange} 
                            hidden
                            className="textInput">
                        </input>
                    </div>
                    <div className="formMessage">
                        <textarea 
                            type="text" 
                            name="message"
                            value={form.message}
                            onChange={handleChange} 
                            maxLength={186}
                            required
                            placeholder="Type your memory here..."
                            className="bigTextInput">
                        </textarea>
                    </div>
                    <button type="submit" className="submitButton" onClick={handleClick}>
                        Generate
                    </button>
                </form>
            </div>
         </>
    )
}
