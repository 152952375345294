import React, { useState, useMemo } from "react"
import { useParams } from "react-router-dom"
import DisplayGrid from "./subcomponents/DisplayGrid"
import Loader from "./subcomponents/Loader"

export default function DigitalGardenDisplay() {
    const [userData, setUserData] = useState({})
    const [loading, setLoading] = useState(true)

    const lambda = process.env.REACT_APP_DG_ENTRY_POINT
    const uniqueId = useParams().id
    
    try {
        useMemo(async () => {
            const response = await fetch(`${lambda}${uniqueId}`, {
                method: "GET",
                mode: "cors",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json"
                }
            })
            const result = await response?.json()
            const data = JSON.parse(await result?.message)
            
            setTimeout(() => setLoading(false), 10000)
            return setUserData(data)
        }, [])
    } catch (error) {
        console.error(error.message)
        console.trace()
    }
    return(
        <>
        <div className="displayMobileLogo">
            <img className="logoImage" src="../../images/logo.png" alt="logo" />
        </div>
        {loading
            ? <Loader /> 
            : userData.status === "Image response recieved."
            ? <>
                <div className="displayContainer">
                <div className="gridContainer">
                    <DisplayGrid userData={userData} />
                </div>
                <div className="vertTextContainer">
                    <img className="displayDesktopLogo" src="../../images/logo.png" alt="logo" />
                    <p>{`INDEX#${userData.id.slice(0, 8)}`}</p>
                </div>
                </div>
            </>
            : window.location.reload()
        }
        </>
    )
}
