import React, { useState, useEffect } from "react"
import DisplayGrid from "./subcomponents/DisplayGrid"

export default function DigitalGardenUpdate() {
    const [userData, setUserData] = useState({
        id: "-",
        name: "-",
        message: "-",
        intro: "-",
        conflict: "-",
        attempt: "-",
        climax: "-",
        resolution: "-",
        reflection: "-",
        sdImg1: "images/placeholder.png",
        sdImg2: "images/placeholder.png",
        sdImg3: "images/placeholder.png",
        sdImg4: "images/placeholder.png",
        sdImg5: "images/placeholder.png",
        sdImg6: "images/placeholder.png"
    })
    const lambda = process.env.REACT_APP_DG_ENTRY_POINT

    try {
        useEffect(() => {
            setInterval(async () => {
                const response = await fetch(`${lambda}ephemera`, {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json"
                    }
                })
                const result = await response?.json()
                const data = JSON.parse(await result?.message)
                if(data.id !== userData.id) {
                    console.log("Data: ", data)
                    setUserData(data)
                }
            }, 30000)
        }, [])
    } catch (error) {
        console.error(error.message)
        console.trace()
    }
    return(
        <>
        <div className="displayMobileLogo">
            <img className="logoImage" src="../../images/logo.png" alt="logo" />
        </div>
            <>
                <div className="displayContainer">
                <div className="gridContainer">
                    <DisplayGrid userData={userData} />
                </div>
                <div className="vertTextContainer">
                    <img className="displayDesktopLogo" src="../../images/logo.png" alt="logo" />
                    <p>{`INDEX#${userData.id.slice(0, 8)}`}</p>
                </div>
                </div>
            </>
        </>
    )
}
